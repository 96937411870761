import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '../Loader/Loader';

import UserPanelTournament from '../UserPanelTournament/UserPanelTournament';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledGlobalHeadingWrapper,
    StyledGlobalScrollWrapper,
    StyledLoaderWrapper,
} from '../../../styles/sharedStyles';

import { StyledUserPanelUserEvents, StyledTextWrapper } from './UserPanelUserEvents.styles';

import useScrollBar from '../../../logic/hooks/useScrollBar';
import { getString } from '../../../strings';

const UserPanelUserEvents = ({
    loadMore,
    hasMore,
    events,
    setEvents,
    removeEvent,
    user,
    setCompetitionDeadlinePopup,
    setPaymentDeadlinePopup,
    setPendingDeadlineExpiredPopup,
    setRegistrationClosedPopup,
    error,
}) => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const [retAllEvents, setRetAllEvents] = useState();
    const [retOverEvents, setRetOverEvents] = useState();

    useEffect(() => {
        if (!events) {
            return;
        }

        const retEvents = events?.filter((event) => event.status !== 'over');
        const retOverEvents = events?.filter((event) => event.status === 'over');

        setRetAllEvents(retEvents);
        setRetOverEvents(retOverEvents);
    }, [events]);

    return (
        <StyledUserPanelUserEvents>
            {error ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 20px 0 20px"
                        hasdeclaredfontsize="46px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="600px"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {error}
                    </StyledText>
                </StyledLoaderWrapper>
            ) : null}
            {!events ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !events.length ? (
                <StyledTextWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredpadding="0 20px 0 20px"
                        hasdeclaredfontsize="46px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredmaxwidth="600px"
                        hasdeclaredlineheight="1.4em"
                        as="h2"
                    >
                        {getString('pages_userPanel_nieUczestniczyszWZadnychZawodach')}
                    </StyledText>
                </StyledTextWrapper>
            ) : (
                <>
                    <StyledGlobalHeadingWrapper>
                        <StyledText
                            hasdeclaredpadding="20px 20px 30px 0"
                            hasdeclaredfontsize="36px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            as="h2"
                        >
                            {getString('User_panel_user_events_moje_zawody')}:
                        </StyledText>
                    </StyledGlobalHeadingWrapper>
                    <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={hasMore}
                            initialLoad={false}
                            getScrollParent={() => scrollElement.current}
                            useWindow={!isScrollBar}
                        >
                            {retAllEvents?.map((event, i) => (
                                <UserPanelTournament
                                    key={i}
                                    event={event}
                                    user={user}
                                    setEvent={(event) =>
                                        setEvents([
                                            ...events.slice(0, i),
                                            event,
                                            ...events.slice(i + 1),
                                        ])
                                    }
                                    removeEvent={removeEvent}
                                    setPendingDeadlineExpiredPopup={setPendingDeadlineExpiredPopup}
                                    setCompetitionDeadlinePopup={setCompetitionDeadlinePopup}
                                    setPaymentDeadlinePopup={setPaymentDeadlinePopup}
                                    setRegistrationClosedPopup={setRegistrationClosedPopup}
                                />
                            ))}
                            <StyledText
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontsize="54px"
                                hasdeclaredpadding={
                                    retAllEvents?.length
                                        ? '32px 20px 32px 20px'
                                        : '0 20px 32px 20px'
                                }
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                            >
                                {getString('pages_userPanel_minione')}
                            </StyledText>
                            {retOverEvents?.map((event, i) => (
                                <UserPanelTournament
                                    key={i}
                                    event={event}
                                    user={user}
                                    removeEvent={removeEvent}
                                    setCompetitionDeadlinePopup={setCompetitionDeadlinePopup}
                                />
                            ))}
                        </InfiniteScroll>
                    </StyledGlobalScrollWrapper>
                </>
            )}
        </StyledUserPanelUserEvents>
    );
};

export default UserPanelUserEvents;
