import { useCallback, useMemo, useState } from 'react';
import PaymentsPopup from '../Payments/PaymentsPopup';
import { getString } from '../../../strings';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledBigLoader } from '../../atoms/Loader/StyledBigLoader';
import { sendPaymentReminder } from '../../../logic/requests/events';

const SendPaymentReminderPopup = ({ eventId, onClose }) => {
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(() => {
        setIsLoading(true);
        sendPaymentReminder(eventId).then((res) => {
            setIsLoading(false);
            setEmailSent(true);
        }).catch(() => {
            setIsLoading(false);
        });
    }, [eventId]);


    const renderContent = useMemo(() => (emailSent ? (
        <div>
            <StyledText
                   hasdeclaredfontsize="18px"
                   hasdeclaredpadding={'50px 20px 20px 20px'}
                   hasdeclaredlineheight="1.4em"
                   hasdeclaredtextalign="center"
            >
                {getString('userPanel_userPanelTournament_payment_reminder_sent')}
            </StyledText>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#6d85ba"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="150px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onClose}
                >
                    {getString('all_trainer_pay_popup_zamknij')}
                </StyledButton>
            </div>
        </div>
    ) : isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <StyledBigLoader />
        </div>
    ) : (
        <>
            <StyledText
                hasdeclaredfontsize="18px"
                hasdeclaredpadding={'50px 20px 20px 20px'}
                hasdeclaredlineheight="1.4em"
                hasdeclaredtextalign="center"
            >
                {getString('userPanel_userPanelTournament_payment_reminder_confirmation')}
            </StyledText>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#6d85ba"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="150px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onSubmit}
                >
                    {getString('userPanel_userPanelTournament_payment_reminder_confirm')}
                </StyledButton>
                <StyledButton
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredbgcolor="#ee745c"
                    hasdeclaredtextalign="center"
                    hasdeclaredcursor="pointer"
                    hasdeclaredwidth="150px"
                    hasdeclaredheight="50px"
                    hasdeclaredfontsize="20px"
                    hasdeclaredborderradius="35px"
                    hasdeclaredmargin="20px 0 0 0"
                    hasdeclaredfontweight="600"
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={onClose}
                >
                    {getString('userPanel_userPanelTournament_payment_reminder_cancel')}
                </StyledButton>
            </div>
        </>
    )), [emailSent, isLoading, onClose, onSubmit]);
        

    return (
        <PaymentsPopup>
            {renderContent}
        </PaymentsPopup>
    );
};

export default SendPaymentReminderPopup;
