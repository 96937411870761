import { getString } from '../../../strings';
import SuccessMessage from '../../molecules/SuccessMessage';

const AddHourSuccess = ({ refs, students, hasFree }) => {
    return (
        <SuccessMessage
            title={getString('UserPanelTrainerTrackHours__form_submit__succ') + ':'}
            infos={[
                {
                    title:
                        getString('UserPanelTrainerTrackHours__form_submit_after__for_who') + ':',
                    value: (() => {
                        const student = students.find(
                            (student) => student.id === parseInt(refs.student.current.value)
                        );
                        return student
                            ? `${student.firstname} ${student.lastname}`
                            : 'Unknown student';
                    })(),
                },
                {
                    title: getString('UserPanelTrainerTrackHours__form_submit_after__hours') + ':',
                    value: `${parseInt(refs.hours.current.value)}${
                        refs.half.current.checked ? '.5' : ''
                    }`,
                },
                ...(hasFree && refs.free.current.checked
                    ? [
                          {
                              title:
                                  getString('UserPanelTrainerTrackHours__form_submit_after__free') +
                                  ':',
                              value: getString('YES'),
                          },
                      ]
                    : []),
                {
                    title: getString('UserPanelTrainerTrackHours__form_submit_after__when') + ':',
                    value: refs.date.current.value.split('-').reverse().join('.'),
                },
            ]}
        />
    );
};

export default AddHourSuccess;