import React, { useCallback, useRef, useState } from 'react';
import { getString } from '../../../strings';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';

import PhoneIcon from '../../atoms/UserPanelStudens/PhoneIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import SendEmailPopup from './SendEmailPopup';

import {
    StyledPreferencesWrapper,
    StyledSelect,
    StyledSelectWrapper,
} from '../../../styles/sharedStyles';
import { StyledButtonsWrapper } from '../../organisms/UserPanelReqElement/UserPanelReqElement.styles';

import {
    StyledPhoneLink,
    StyledEditHandler,
    StyledColumn,
    StyledRow,
    StyledUserListElement,
    StyledUserListEditElement,
    StyledEditElementInputWrapper,
    StyledCloseForm,
    StyledSaveForm,
    StyledEdirRole,
    StyledEmailHandler,
} from './UserPanelStudentElement.styles';

import {
    togglePref,
    getFormData,
    handleSubmit,
    handleSubmitParent,
    handleSubmitNonParent,
    handleDeleteParent,
    noneString,
    defaultValuesParent,
} from './UserPanelStudentElement.data';
import { sendWelcomeEmail } from '../../../logic/requests/students';
import DocumentIcon from './DocumentIcon';
import PaymentsPopup from '../Payments/PaymentsPopup';

const UserPanelStudentElement = ({ user, student, setStudent, canEdit, parents, former }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isRoleEdit, setIsRoleEdit] = useState(false);
    const [isAddParent, setIsAddParent] = useState(false);
    const [errors, setErrors] = useState({});

    const refs = {
        student_firstname: useRef(),
        student_lastname: useRef(),
        student_phone: useRef(),
        parent_firstname: useRef(),
        parent_lastname: useRef(),
        parent_phone: useRef(),
        parent_email: useRef(),
    };

    const defaultValues = {
        student_firstname: student.firstname,
        student_lastname: student.lastname,
        student_phone: student.phone,
        ...(student.parent
            ? {
                  parent_firstname: student.parent.firstname,
                  parent_lastname: student.parent.lastname,
              }
            : {}),
        parent_phone: student.parent?.phone,
        parent_email: student.parent?.email,
    };

    const refsParent = {
        id: useRef(),
        firstname: useRef(),
        lastname: useRef(),
        email: useRef(),
        phone: useRef(),
    };

    const [errorsParent, setErrorsParent] = useState(defaultValuesParent);
    const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

    const handleSubmitForm = useCallback(() => {
        handleSubmit(
            getFormData(refs, defaultValues),
            student,
            setStudent,
            () => setIsEdit(false),
            setErrors
        );
    }, [defaultValues, refs, setErrors, setStudent, student]);

    const handleClickEmailButton = useCallback(() => {
        setShowEmailConfirmation(true);
    }, []);

    const editElement = (
        <StyledUserListEditElement>
            <StyledCloseForm onClick={() => setIsEdit(false)}>
                <CloseIcon />
            </StyledCloseForm>
            <StyledSaveForm onClick={handleSubmitForm}>
                <AcceptIcon />
            </StyledSaveForm>
            <StyledEditElementInputWrapper error={errors.student_firstname}>
                <div>
                    <label htmlFor="student_firstname">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('Trainings_trainings_student_element_imieUcznia')}:
                        </StyledText>
                    </label>
                    <input
                        id="student_firstname"
                        ref={refs.student_firstname}
                        type="text"
                        placeholder="Wpisz imię ucznia"
                        defaultValue={defaultValues.student_firstname}
                    />
                </div>
                {errors.student_firstname ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString(`UserPanelStudentElement__edit__errors__error`)}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            <StyledEditElementInputWrapper error={errors.student_lastname}>
                <div>
                    <label htmlFor="student_lastname">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('Trainings_trainings_student_element_nazwiskoUcznua')}:
                        </StyledText>
                    </label>
                    <input
                        id="student_lastname"
                        ref={refs.student_lastname}
                        type="text"
                        placeholder="Wpisz nazwisko ucznia"
                        defaultValue={defaultValues.student_lastname}
                    />
                </div>
                {errors.student_lastname ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString(`UserPanelStudentElement__edit__errors__error`)}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            <StyledEditElementInputWrapper error={errors.student_phone}>
                <div>
                    <label htmlFor="studenttel">
                        <StyledText
                            hasdeclaredfontsize="22px"
                            hasdeclaredlineheight="1.2em"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('Trainings_trainings_student_element_nrUcznia')}:
                        </StyledText>
                    </label>
                    <input
                        id="studenttel"
                        ref={refs.student_phone}
                        type="text"
                        placeholder="Wpisz numer telefonu ucznia"
                        defaultValue={defaultValues.student_phone}
                    />
                </div>
                {errors.student_phone ? (
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredfontcolor="#F74017"
                        hasdeclaredfontweight="bold"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.3em"
                        as="h4"
                    >
                        {getString('UserPanelStudentElement__edit__errors__error')}
                    </StyledText>
                ) : undefined}
            </StyledEditElementInputWrapper>
            {student.parent ? (
                <>
                    <StyledEditElementInputWrapper error={errors.parent_firstname}>
                        <div>
                            <label htmlFor="studentparent_firstname">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('Trainings_trainings_student_element_imieRodzica')}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparent_firstname"
                                ref={refs.parent_firstname}
                                type="text"
                                placeholder="Wpisz imię rodzica"
                                defaultValue={defaultValues.parent_firstname}
                            />
                        </div>
                        {errors.parent_firstname ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                                as="h4"
                            >
                                {getString(`UserPanelStudentElement__edit__errors__error`)}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper error={errors.parent_lastname}>
                        <div>
                            <label htmlFor="studentparent_lastname">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString(
                                        'Trainings_trainings_student_element_nazwiskoRodzica'
                                    )}
                                    :
                                </StyledText>
                            </label>
                            <input
                                id="studentparent_lastname"
                                ref={refs.parent_lastname}
                                type="text"
                                placeholder="Wpisz nazwisko rodzica"
                                defaultValue={defaultValues.parent_lastname}
                            />
                        </div>
                        {errors.parent_lastname ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                                as="h4"
                            >
                                {getString(`UserPanelStudentElement__edit__errors__error`)}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper error={errors.parent_phone}>
                        <div>
                            <label htmlFor="studentparenttel">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('Trainings_trainings_student_element_nrRodzica')}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparenttel"
                                ref={refs.parent_phone}
                                type="text"
                                placeholder="Wpisz numer telefonu rodzica"
                                defaultValue={defaultValues.parent_phone}
                            />
                        </div>
                        {errors.parent_phone ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                                as="h4"
                            >
                                {getString(`UserPanelStudentElement__edit__errors__error`)}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper error={errors.parent_email}>
                        <div>
                            <label htmlFor="studentparentemail">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    {getString('Trainings_trainings_student_element_emailRodzica')}:
                                </StyledText>
                            </label>
                            <input
                                id="studentparentemail"
                                ref={refs.parent_email}
                                type="text"
                                placeholder="Wpisz adres e-mail rodzica"
                                defaultValue={defaultValues.parent_email}
                            />
                        </div>
                        {errors.parent_email ? (
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredfontweight="bold"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                                as="h4"
                            >
                                {getString(`UserPanelStudentElement__edit__errors__error`)}
                            </StyledText>
                        ) : undefined}
                    </StyledEditElementInputWrapper>
                </>
            ) : undefined}
        </StyledUserListEditElement>
    );

    const basicElement = (
        <>
            <StyledRow>
                {showEmailConfirmation ? (
                    <SendEmailPopup
                        onClose={() => setShowEmailConfirmation(false)}
                        userId={student.id}
                    />
                ) : null}
                <StyledColumn>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString('UserPanelStudents__list__entry__student')}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                    >
                        {`${student.firstname} ${student.lastname}`}
                    </StyledText>
                </StyledColumn>
                <StyledColumn>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString('UserPanelStudents__list__entry__student_phone')}:
                    </StyledText>
                    <StyledPhoneLink href={`tel:${student.phone}`}>
                        {student.phone} <PhoneIcon />
                    </StyledPhoneLink>
                </StyledColumn>
            </StyledRow>
            {student.parent ? (
                <StyledRow>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('UserPanelStudents__list__entry__parent')}:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {student.parent.firstname ?? noneString}{' '}
                            {student.parent.lastname ?? noneString}
                        </StyledText>
                    </StyledColumn>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            {getString('UserPanelStudents__list__entry__parent_phone')}:
                        </StyledText>
                        {student.parent.phone ? (
                            <StyledPhoneLink href={`tel:${student.parent.phone}`}>
                                {student.parent.phone} <PhoneIcon />
                            </StyledPhoneLink>
                        ) : (
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {noneString}
                            </StyledText>
                        )}
                    </StyledColumn>
                </StyledRow>
            ) : undefined}
            <StyledRow>
                <StyledPreferencesWrapper $isyes={student.preferences[user.id] > 0}>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString('UserPanelStudents__list__entry__preferred')}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        onClick={() => togglePref(user.id, student, setStudent, 'preferred')}
                    >
                        {student.preferences[user.id] > 0 ? getString('YES') : getString('NO')}
                    </StyledText>
                </StyledPreferencesWrapper>
                <StyledPreferencesWrapper $isyes={student.preferences[user.id] < 0}>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {getString('UserPanelStudents__list__entry__forbidden')}:
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        onClick={() => togglePref(user.id, student, setStudent, 'forbidden')}
                    >
                        {student.preferences[user.id] < 0 ? getString('YES') : getString('NO')}
                    </StyledText>
                </StyledPreferencesWrapper>
            </StyledRow>
            {canEdit ? (
                <StyledRow>
                    <StyledColumn>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#6786BE"
                        >
                            Email ucznia:
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="20px"
                            hasdeclaredfontweight="600"
                            hasdeclaredlineheight="1.4em"
                        >
                            {student.email}
                        </StyledText>
                    </StyledColumn>
                    {student.parent ? (
                        <StyledColumn>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Email rodzica:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredmargin="0 0 0 0"
                            >
                                {student.parent.email ?? noneString}
                            </StyledText>
                        </StyledColumn>
                    ) : null}
                </StyledRow>
            ) : null}
            {canEdit ? (
                <StyledEditHandler onClick={() => setIsEdit(true)}>
                    <PencilIcon />
                </StyledEditHandler>
            ) : null}
            {canEdit ? (
                <StyledEdirRole onClick={() => setIsRoleEdit(true)}>
                    <CloseIcon />
                </StyledEdirRole>
            ) : null}
            {canEdit && !former ? (
                <StyledEmailHandler onClick={handleClickEmailButton}>
                    <DocumentIcon />
                </StyledEmailHandler>
            ) : null}
        </>
    );

    const editRoleElement = (
        <>
            <StyledCloseForm onClick={() => setIsRoleEdit(false)}>
                <CloseIcon />
            </StyledCloseForm>
            <StyledSaveForm
                onClick={() => {
                    const data = getFormData(refsParent, defaultValuesParent);
                    const id = parseInt(data.id);
                    if (!isNaN(id)) {
                        handleSubmitParent(
                            student.id,
                            parents.find((parent) => parent.id === id),
                            setStudent,
                            () => {
                                setIsAddParent(false);
                                setIsRoleEdit(false);
                            }
                        );
                    } else {
                        handleSubmitNonParent(student.id, data, setStudent, () => {
                            setIsAddParent(false);
                            setIsRoleEdit(false);
                        });
                    }
                }}
            >
                <AcceptIcon />
            </StyledSaveForm>
            {student.parent ? (
                <>
                    <StyledRow>
                        <StyledColumn>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Imię:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {student.parent.firstname ?? noneString}
                            </StyledText>
                        </StyledColumn>
                        <StyledColumn>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Nazwisko:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {student.parent.lastname ?? noneString}
                            </StyledText>
                        </StyledColumn>
                    </StyledRow>
                    <StyledRow>
                        <StyledColumn>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Email:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {student.parent.email ?? noneString}
                            </StyledText>
                        </StyledColumn>
                        <StyledColumn>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredfontcolor="#6786BE"
                            >
                                Telefon:
                            </StyledText>
                            {student.parent.phone ? (
                                <StyledPhoneLink href={`tel:${student.parent.phone}`}>
                                    {student.parent.phone} <PhoneIcon />
                                </StyledPhoneLink>
                            ) : (
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {noneString}
                                </StyledText>
                            )}
                        </StyledColumn>
                    </StyledRow>
                </>
            ) : undefined}
            <StyledRow>
                <StyledButtonsWrapper ismgtop center={true}>
                    {student.parent ? (
                        <StyledButton
                            hasdeclaredfontweight="600"
                            hasdeclaredfontsize="22px"
                            hasdeclaredtextalign="center"
                            hasdeclaredbgcolor="#ee745c"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredborderradius="25px"
                            hasdeclaredcursor="pointer"
                            hasdeclaredmargin="20px 0 0 0"
                            className="hover-red-effect"
                            onClick={() =>
                                handleDeleteParent(student.id, setStudent, () =>
                                    setIsRoleEdit(false)
                                )
                            }
                        >
                            USUŃ RODZICA
                        </StyledButton>
                    ) : (
                        <StyledButton
                            hasdeclaredfontweight="600"
                            hasdeclaredfontsize="22px"
                            hasdeclaredtextalign="center"
                            hasdeclaredbgcolor="#6786BE"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredborderradius="25px"
                            hasdeclaredcursor="pointer"
                            hasdeclaredmargin="20px 0 0 0"
                            onClick={() => setIsAddParent(true)}
                            whileHover={{ scale: 0.95 }}
                        >
                            DODAJ RODZICA
                        </StyledButton>
                    )}
                </StyledButtonsWrapper>
            </StyledRow>
            {isAddParent ? (
                <>
                    <StyledSelectWrapper>
                        <StyledSelect type="select" ref={refsParent.id}>
                            <option value="">-- WYBIERZ RODZICA --</option>
                            {parents.map((parent) => (
                                <option key={parent.id} value={parent.id}>
                                    {parent.lastname} {parent.firstname} ({parent.email})
                                </option>
                            ))}
                        </StyledSelect>
                    </StyledSelectWrapper>
                    <StyledEditElementInputWrapper $errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Imię:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.firstname}
                                type="text"
                                placeholder="Imię rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.firstname}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper $errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Nazwisko:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.lastname}
                                type="text"
                                placeholder="Nazwisko rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.lastname}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper $errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    E-mail:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.email}
                                type="text"
                                placeholder="Adres e-mail rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.email}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                    <StyledEditElementInputWrapper $errorpdleft>
                        <div>
                            <label htmlFor="">
                                <StyledText
                                    hasdeclaredfontsize="22px"
                                    hasdeclaredlineheight="1.2em"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#6786BE"
                                >
                                    Telefon:
                                </StyledText>
                            </label>
                            <input
                                ref={refsParent.phone}
                                type="text"
                                placeholder="Numer telefonu rodzica"
                            />
                        </div>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredfontweight="bold"
                            hasdeclaredlineheight="1.3em"
                            as="h4"
                        >
                            {errorsParent.phone}
                        </StyledText>
                    </StyledEditElementInputWrapper>
                </>
            ) : null}
        </>
    );

    return (
        <StyledUserListElement $former={former}>
            {isRoleEdit ? editRoleElement : isEdit ? editElement : basicElement}
        </StyledUserListElement>
    );
};

export default UserPanelStudentElement;
