import styled from 'styled-components';

export const StyledDoneDataElementWrapperData = styled.div`
    height: 100%;
    width: 100%;
    background: #fff;
    border: 4px solid #6786be;
    display: flex;
    justify-content: space-between;
    border-radius: 25px;
    overflow-x: scroll;
    padding: 5px 0;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
`;
