import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sessionService } from 'redux-react-session';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { PAGEPANEL } from '../constants/UserPanel';
import { getString } from '../../strings';

import useWindowSize from '../../utils/getWindowSize';

import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';
import LoginLink from '../organisms/LoginLink/LoginLink';
import LogoLink from '../organisms/Logo/LogoLink';
import TournamentCubes from '../organisms/Tournament/TournamentCubes';
import UserPanelParentSettlements from '../organisms/UserPanelParent/UserPanelParentSettlements';
import UserPanelAddRole from '../organisms/UserPanelAddRole/UserPanelAddRole';
import UserPanelPreferences from '../organisms/UserPanelPreferences/UserPanelPreferences';
import UserPanelStudentsPayments from '../organisms/UserPanelStudentsPayments/UserPanelStudentsPayments';
import UserPanelEmployeeConfiguration from '../organisms/UserPanelEmplyeeConfiguration/UserPanelEmployeeConfiguration';
import UserPanelSubjects from '../organisms/UserPanelSubjects/UserPanelSubjects';
import UserPanelAdditions from '../organisms/UserPanelAdditions/UserPanelAdditions';
import UserPanelUsersMenu from '../organisms/UserPanelUsers/UserPanelUsersMenu';
import AllTrainersPage from '../organisms/AllTrainersPage/AllTrainersPage';
import UserPanelAnnouncements from '../organisms/UserPanelAnnouncements/UserPanelAnnouncements';
import UserEditPanel from '../organisms/UserPanel/UserEditPanel';
import PaymentsPopup from '../organisms/Payments/PaymentsPopup';
import UserPanelReq from '../organisms/UserPanelReq/UserPanelReq';
import UserPanelStudents from '../organisms/UserPanelStudents/UserPanelStudents';
import UserPanelTrainerAddHours from '../organisms/UserPanelTrainerTrackHours/UserPanelTrainerTrackHours.js';
import UserPanelTrainerPay from '../organisms/UserPanelTrainer/UserPanelTrainerPay';
import UserPanelAllTrainerPay from '../organisms/UserPanelTrainer/UserPanelAllTrainerPay';
import UserPanelStudentsMenu from '../organisms/UserPanelStudents/UserPanelStudentsMenu';
// import UserPanelDiplomas from '../organisms/UserPanelDiplomas/UserPanelDiplomas';
import UserPanelManageContracts from '../organisms/UserPanelManageContracts/UserPanelManageContracts';
import UserPanelAllEvents from '../organisms/UserPanelAllEvents/UserPanelAllEvents';
import UserPanelUserEvents from '../organisms/UserPanelUserEvents/UserPanelUserEvents';

import { StyledUserPanel } from '../atoms/UserPanel/StyledUserPanel';
import { StyledRightShape } from '../atoms/UserPanel/StyledRightShape';
import { StyledLeftPanel } from '../molecules/UserPanel/StyledLeftPanel';
import { StyledRightPanel } from '../atoms/UserPanel/StyledRightPanel';
import { StyledText } from '../atoms/Text/StyledText';
import { StyledUserMenu } from '../atoms/UserPanel/StyledUserMenu';
import { StyledButton } from '../atoms/Button/StyledButton';
import { StyledLoggoutWrapper } from '../molecules/UserPanel/StyledLoggoutWrapper';
import { StyledRoleWrapper } from '../atoms/UserPanel/StyledRoleWrapper';

import { getEventsForCurrentUser } from '../../logic/requests/events.js';

import REGISTER_STATUS from '../constants/register_status.json';
import { getDateTimeString } from '../../logic/eventtime';
import { getAnalysesUser, getAnalysesTrainer } from '../../logic/requests/trainings';
import UserPanelSummary from '../organisms/UserPanelSummary/UserPanelSummary';
import UserPanelUsersNewsletter from '../organisms/UserPanelUsersNewsletter';
import UserPanelEmployees from '../organisms/UserPanelEmployees/UserPanelEmployees';
import UserPanelButton from '../atoms/UserPanelButton/UserPanelButton';
import useScrollBar from '../../logic/hooks/useScrollBar';
import { StyledLoaderWrapper } from '../../styles/sharedStyles';

const UserPanel = () => {
    const width = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const [user, setUser] = useState();
    const [isAfterTransactionPopupOpen, setIsAfterTransactionPopupOpen] = useState(
        state?.afterTransaction
    );
    const [isAfterResignPopupOpen, setIsAfterResignPopupOpen] = useState(false);
    const [isAfterResignReturnPopupOpen, setIsAfterResignReturnPopupOpen] = useState(false);
    const [isAfterResignNoReturnPopupOpen, setIsAfterResignNoReturnPopupOpen] = useState(false);
    const [competitionDeadlinePopup, setCompetitionDeadlinePopup] = useState(null);
    const [paymentDeadlinePopup, setPaymentDeadlinePopup] = useState(null);
    const [registrationClosedPopup, setRegistrationClosedPopup] = useState(null);
    const [isPanelLocked, setIsPanelLocked] = useState(false);
    const [isLoggout, setIsLoggout] = useState(false);
    const [events, setEvents] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [isError, setIsError] = useState({ message: null });
    const [pendingDeadlineExpiredPopup, setPendingDeadlineExpiredPopup] = useState(null);

    const { scrollElement, isScrollBar } = useScrollBar();
    const { scrollElement: scrollElement2, isScrollBar: isScrollBar2 } = useScrollBar();
    const { panel: panelParam, subpanel } = useParams();

    const loggoutWrapper = useRef(null);
    const pagePanel = panelParam ?? PAGEPANEL.EVENTS;
    const setPagePanel = (panel, subpanel = undefined) =>
        navigate(panel ? `/my-account/${panel}${subpanel ? `/${subpanel}` : ''}` : '/my-account');

    const showFormer = useMemo(() => {
        return user && ['manager', 'admin'].some((role) => user.roles.includes(role));
    }, [user]);

    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}

                if (!(user && Object.keys(user).length)) {
                    navigate('/login', {
                        replace: true,
                        state: { loginRedirect: '/my-account' },
                    });
                }
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const changePanel = useCallback(
        (panel, subpanel = undefined) => {
            if (
                !isPanelLocked ||
                (isPanelLocked &&
                    window.confirm(getString('pages_userPanel_daneZostanaUtraconePo')))
            ) {
                setPagePanel(panel, subpanel);
                setIsPanelLocked(false);
            }
        },
        [setPagePanel, isPanelLocked, setIsPanelLocked]
    );

    useEffect(() => {
        const { current } = scrollElement;

        if (!current) return;

        if (width < 926)
            current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }, [pagePanel]);

    const loadEvents = useCallback(
        () =>
            getEventsForCurrentUser({ cursorId: events ? events[events.length - 1].id : 0 })
                .then((res) => {
                    setEvents((events) => [
                        ...(events ?? []),
                        ...(Array.isArray(res) ? res : []).filter(
                            (event) =>
                                !(
                                    event.user_register_status === REGISTER_STATUS['UNCONFIRMED'] &&
                                    event.status === 'over'
                                )
                        ),
                    ]);
                    setIsError({ message: null });
                    if (!(Array.isArray(res) && res.length)) setHasMore(false);
                })
                .catch(() => {
                    setHasMore(false);
                    setIsError({ message: 'Błąd podczas ładowania zawodów' });
                }),
        [events]
    );

    useEffect(() => {
        if (user && Object.keys(user).length) {
            loadEvents();
        }
    }, [user]);

    const removeEvent = (id, has_return = null) => {
        const removedEvent = events.find((event) => event.id === id);

        setEvents(events.filter((event) => event.id !== id));

        if (!removedEvent) return;

        if (
            // są płatności online
            removedEvent.payment_method === 'p24' &&
            // nie jest rezerwowy
            !removedEvent.reserve &&
            // jest ZATWIERDZONY => zapłacił
            removedEvent.user_register_status === REGISTER_STATUS['CONFIRMED']
        ) {
            // może mieć zwrot
            const date = getDateTimeString(new Date(removedEvent.payment_return_deadline));

            if (removedEvent.payment_return_deadline_expired) {
                // termin zwrotu został przekroczony
                // bez zwrotu ale ze specjalną informacją
                setIsAfterResignNoReturnPopupOpen(date);
            } else {
                if (typeof has_return === 'boolean') {
                    // serwer wie lepiej - czy termin zwrotu nie został przekroczony?
                    if (has_return) {
                        // rezygnacja ze zwrotem
                        setIsAfterResignReturnPopupOpen(true);
                    } else {
                        // bez zwrotu ale ze specjalną informacją
                        setIsAfterResignNoReturnPopupOpen(date);
                    }
                } else {
                    // brak danych z serwera...
                    // rezygnacja ze zwrotem
                    setIsAfterResignReturnPopupOpen(true);
                }
            }
        } else {
            // nie może mieć zwrotu
            // bez zwrotu
            setIsAfterResignPopupOpen(true);
        }
    };

    const handleOpenLogout = () => {
        setIsLoggout(!isLoggout);

        if (isLoggout) return;

        setTimeout(
            () =>
                loggoutWrapper.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest',
                }),
            50
        );
    };

    const [analyses, setAnalyses] = useState();
    const [analysesErr, setAnalysesErr] = useState(false);

    useEffect(() => {
        if (!user) return;

        if (analyses) return;

        const getAnalyses = user.roles.includes('trainer') ? getAnalysesTrainer : getAnalysesUser;

        getAnalyses()
            .then((res) => {
                setAnalyses(res);
            })
            .catch(() => {
                setAnalysesErr(true);
            });
    }, [user, analyses, setAnalyses, setAnalysesErr]);

    if (!(user && Object.keys(user).length)) return <></>;

    return (
        <>
            <HelmetTemplate
                title={getString('seo_user_panel_title')}
                desc={getString('seo_user_panel_desc')}
                ogTitle={getString('seo_user_panel_twitter_title')}
            />
            <StyledUserPanel
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <StyledRightShape />
                <TournamentCubes right2="180px" top2="180px" bottom3="20px" right3="80px" />
                <LoginLink />
                <LogoLink />
                {isAfterTransactionPopupOpen && !state?.afterTransactionErr ? (
                    state.transactionType === 'lessons' ? (
                        <>
                            {setIsAfterTransactionPopupOpen(false)}
                            <Navigate
                                to="/my-account/payments"
                                state={{ ...state, afterTransaction: false }}
                            />
                        </>
                    ) : (
                        <PaymentsPopup
                            title={getString('pages_userPanel_dziekujemyZaOplacenieUdzialu')}
                            onClose={() => {
                                // ukryj popup
                                setIsAfterTransactionPopupOpen(false);
                                // nie pokazuj po odświeżeniu
                                navigate(location.pathname, {
                                    state: { ...state, afterTransaction: false },
                                    replace: true,
                                });
                            }}
                        />
                    )
                ) : null}
                {isAfterResignPopupOpen ? (
                    <PaymentsPopup
                        title={getString('pages_userPanel_pomyslnieZrezygnowanoZZawodow')}
                        onClose={() => setIsAfterResignPopupOpen(false)}
                    />
                ) : null}
                {isAfterResignReturnPopupOpen ? (
                    <PaymentsPopup
                        title={getString('pages_userPanel_pomyslnieZrezygnowanoZZawodowZwrot')}
                        onClose={() => setIsAfterResignReturnPopupOpen(false)}
                    />
                ) : null}
                {isAfterResignNoReturnPopupOpen ? (
                    <PaymentsPopup
                        title={`${getString(
                            'pages_userPanel_pomyslnieZrezygnowanoZZawodowBezZwrot'
                        )} ${isAfterResignNoReturnPopupOpen}`}
                        onClose={() => setIsAfterResignNoReturnPopupOpen(null)}
                    />
                ) : null}
                {competitionDeadlinePopup ? (
                    <PaymentsPopup
                        title={`${getString(
                            'pages_userPanel_terminKonkurencjiMinal'
                        )} ${competitionDeadlinePopup}`}
                        onClose={() => setCompetitionDeadlinePopup(null)}
                    />
                ) : null}
                {paymentDeadlinePopup ? (
                    <PaymentsPopup
                        title={`${getString(
                            'pages_userPanel_payment_deadline_expired'
                        )} ${paymentDeadlinePopup}`}
                        onClose={() => setPaymentDeadlinePopup(null)}
                    />
                ) : null}
                {registrationClosedPopup ? (
                    <PaymentsPopup
                        title={`${getString(
                            'pages_userPanel_registration_closed'
                        )} ${registrationClosedPopup}`}
                        onClose={() => setRegistrationClosedPopup(null)}
                    />
                ) : null}
                {pendingDeadlineExpiredPopup ? (
                    <PaymentsPopup
                        title={`${getString(
                            'pages_userPanel_pending_deadline_expired'
                        )} ${pendingDeadlineExpiredPopup}`}
                        onClose={() => setPendingDeadlineExpiredPopup(null)}
                    />
                ) : null}
                <AnimatePresence>
                    <StyledLeftPanel
                        isscrollbar={isScrollBar}
                        hasscroll={[
                            PAGEPANEL.TRAINER_TRACK_HOURS,
                            PAGEPANEL.TRAINER_PAY,
                            PAGEPANEL.STUDENTS,
                            PAGEPANEL.PAYMENTS,
                            PAGEPANEL.ADD_ROLE,
                            PAGEPANEL.ALL_PAY,
                            PAGEPANEL.ADDITIONS,
                            PAGEPANEL.EMPLOYE_CONFIGURATION,
                            PAGEPANEL.SUBJECTS,
                            PAGEPANEL.EMPLOYEES,
                            PAGEPANEL.SUMMARY,
                            PAGEPANEL.ALL_EVENTS,
                            PAGEPANEL.EVENTS,
                            PAGEPANEL.STUDENTS_PAYMENTS,
                            PAGEPANEL.MANAGE_CONTRACTS,
                            // PAGEPANEL.DIPLOMAS,
                        ].includes(pagePanel)}
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            key={pagePanel}
                            ref={scrollElement}
                        >
                            {(() => {
                                switch (pagePanel) {
                                    case PAGEPANEL.ALL_EVENTS:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelAllEvents user={user} />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.EVENTS:
                                        return (
                                            <UserPanelUserEvents
                                                loadMore={loadEvents}
                                                hasMore={hasMore}
                                                events={events}
                                                setEvents={setEvents}
                                                removeEvent={removeEvent}
                                                user={user}
                                                error={isError.message}
                                                setCompetitionDeadlinePopup={
                                                    setCompetitionDeadlinePopup
                                                }
                                                setPendingDeadlineExpiredPopup={
                                                    setPendingDeadlineExpiredPopup
                                                }
                                                setPaymentDeadlinePopup={setPaymentDeadlinePopup}
                                                setRegistrationClosedPopup={
                                                    setRegistrationClosedPopup
                                                }
                                            />
                                        );
                                    case PAGEPANEL.REQ:
                                        return user.roles.includes('trainer') ? (
                                            <Navigate to="/my-account" replace={true} />
                                        ) : (
                                            <UserPanelReq
                                                analyses={analyses}
                                                setAnalyses={setAnalyses}
                                                err={analysesErr}
                                            />
                                        );
                                    case PAGEPANEL.REQ_TRAINER:
                                        return user.roles.includes('trainer') ? (
                                            <UserPanelReq
                                                trainer={true}
                                                analyses={analyses}
                                                setAnalyses={setAnalyses}
                                                err={analysesErr}
                                            />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.USERDATA:
                                        return (
                                            <UserEditPanel setIsPanelLocked={setIsPanelLocked} />
                                        );
                                    case PAGEPANEL.LESSONS:
                                        return (
                                            <StyledLoaderWrapper $isColumn>
                                                <StyledText
                                                    hasdeclaredpadding="0px 20px"
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                    as="p"
                                                >
                                                    {getString('UserPanel__lessons__content_1')}
                                                    <br />
                                                    <br />
                                                </StyledText>
                                                <StyledText
                                                    hasdeclaredpadding="0px 20px"
                                                    hasdeclaredfontsize="22px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                    as="p"
                                                >
                                                    {getString('UserPanel__lessons__content_2')}
                                                </StyledText>
                                            </StyledLoaderWrapper>
                                        );
                                    case PAGEPANEL.PAYMENTS:
                                        return user &&
                                            ((['student', 'former_student'].some((role) =>
                                                user.roles.includes(role)
                                            ) &&
                                                !user.parent) ||
                                                user.children?.length) ? (
                                            <UserPanelParentSettlements user={user} />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.USERS:
                                        return (() => {
                                            switch (subpanel) {
                                                case PAGEPANEL.USERS_NEWSLETTER:
                                                    return <UserPanelUsersNewsletter />;
                                                default:
                                                    return (
                                                        <Navigate to="/my-account" replace={true} />
                                                    );
                                            }
                                        })();
                                    case PAGEPANEL.STUDENTS:
                                        return (() => {
                                            switch (subpanel) {
                                                case PAGEPANEL.STUDENTS_LIST:
                                                    return <UserPanelStudents user={user} />;
                                                case PAGEPANEL.FORMER_STUDENTS_LIST:
                                                    return (
                                                        <UserPanelStudents
                                                            user={user}
                                                            former={true}
                                                        />
                                                    );
                                                case PAGEPANEL.STUDENTS_PREFS:
                                                    return <UserPanelPreferences user={user} />;
                                                default:
                                                    return (
                                                        <Navigate to="/my-account" replace={true} />
                                                    );
                                            }
                                        })();
                                    case PAGEPANEL.TRAINER_TRACK_HOURS:
                                        return <UserPanelTrainerAddHours user={user} />;
                                    case PAGEPANEL.ADD_ROLE:
                                        return ['trainer_new', 'manager', 'admin'].some((role) =>
                                            user.roles.includes(role)
                                        ) ? (
                                            <UserPanelAddRole />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.TRAINER_PAY:
                                        return <UserPanelTrainerPay user={user} />;
                                    case PAGEPANEL.ALL_PAY:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelAllTrainerPay />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.EMPLOYEES:
                                        return ['admin', 'accounting'].some((role) =>
                                            user.roles.includes(role)
                                        ) ? (
                                            <UserPanelEmployees />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.STUDENTS_PAYMENTS:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelStudentsPayments />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );
                                    case PAGEPANEL.SUMMARY:
                                        return ['manager', 'admin'].some((role) =>
                                            user.roles.includes(role)
                                        ) ? (
                                            <UserPanelSummary user={user} />
                                        ) : undefined;
                                    case PAGEPANEL.EMPLOYE_CONFIGURATION:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelEmployeeConfiguration />
                                        ) : undefined;
                                    case PAGEPANEL.SUBJECTS:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelSubjects />
                                        ) : undefined;
                                    case PAGEPANEL.ADDITIONS:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelAdditions />
                                        ) : undefined;
                                    case PAGEPANEL.MANAGE_CONTRACTS:
                                        return user.roles.includes('admin') ? (
                                            <UserPanelManageContracts />
                                        ) : undefined;
                                    // case PAGEPANEL.DIPLOMAS:
                                    //     return user.roles.includes('admin') ? (
                                    //         <UserPanelDiplomas />
                                    //     ) : undefined;
                                    case PAGEPANEL.TRAINERS:
                                        return ['student', 'trainer'].some((role) =>
                                            user.roles.includes(role)
                                        ) || user.hasChildrenWithActiveStudentRole ? (
                                            <AllTrainersPage />
                                        ) : undefined;
                                    case PAGEPANEL.ANNOUNCEMENTS:
                                        return user && user.roles.length > 0 ? (
                                            <UserPanelAnnouncements
                                                isAdmin={user.roles.includes('admin')}
                                                isManager={user.roles.includes('manager')}
                                            />
                                        ) : (
                                            <Navigate to="/my-account" replace={true} />
                                        );

                                    default:
                                        return <Navigate to="/my-account" replace={true} />;
                                }
                            })()}
                        </motion.div>
                    </StyledLeftPanel>
                </AnimatePresence>
                <StyledRightPanel isscrollbar={isScrollBar2} ref={scrollElement2}>
                    <StyledText
                        hasdeclaredfontsize="48px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 20px 20px 20px"
                        hasdeclaredtexttransform="uppercase"
                    >
                        {getString('pages_userPanel_mojeKonto')}
                    </StyledText>
                    {user?.roles?.length ? (
                        <StyledRoleWrapper>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="0 20px 10px 20px"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor="#ee745c"
                            >
                                {getString('pages_userPanel_typKonta')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="24px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="5px 20px"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor="#f9ec4b"
                                hasdeclaredlineheight="1.4em"
                            >
                                {user.roles.map((role) => getString(`roles_${role}`)).join(', ')}
                            </StyledText>
                        </StyledRoleWrapper>
                    ) : null}
                    <StyledUserMenu>
                        {user && user.roles.includes('admin') ? (
                            <>
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.ALL_PAY}
                                    onClick={() => changePanel(PAGEPANEL.ALL_PAY)}
                                    label={getString('pages_userPanel_wynagrodzenie_trenerow')}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.STUDENTS_PAYMENTS}
                                    onClick={() => changePanel(PAGEPANEL.STUDENTS_PAYMENTS)}
                                    label={getString('pages_userPanel_students_payments')}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.ALL_EVENTS}
                                    onClick={() => changePanel(PAGEPANEL.ALL_EVENTS)}
                                    label={getString('pages_userPanel_wszystkie_zawody')}
                                />
                            </>
                        ) : null}
                        <UserPanelButton
                            isActive={pagePanel === PAGEPANEL.EVENTS}
                            onClick={() => changePanel(PAGEPANEL.EVENTS)}
                            label={getString('pages_userPanel_mojeZawody')}
                        />
                        {user && user.roles.includes('admin') ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.ADDITIONS}
                                onClick={() => changePanel(PAGEPANEL.ADDITIONS)}
                                label={getString('pages_userPanel_additions')}
                            />
                        ) : null}
                        {user && user.roles.includes('trainer') ? (
                            <>
                                <UserPanelButton
                                    onClick={() => window.open('/trainer', '_blank')}
                                    label={getString('pages_userPanel_panelTrenerski')}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.TRAINER_TRACK_HOURS}
                                    onClick={() => changePanel(PAGEPANEL.TRAINER_TRACK_HOURS)}
                                    label={getString('pages_userPanel_dodajGodziny')}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.TRAINER_PAY}
                                    onClick={() => changePanel(PAGEPANEL.TRAINER_PAY)}
                                    label={getString('pages_userPanel_wynagrodzenie')}
                                />
                                <UserPanelStudentsMenu
                                    subpanel={subpanel}
                                    showFormer={showFormer}
                                    changePanel={changePanel}
                                    isOpen={pagePanel === PAGEPANEL.STUDENTS}
                                />
                            </>
                        ) : null}
                        {user && user.roles.includes('admin') ? (
                            <>
                                <UserPanelUsersMenu
                                    subpanel={subpanel}
                                    changePanel={changePanel}
                                    isOpen={pagePanel === PAGEPANEL.USERS}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.SUBJECTS}
                                    onClick={() => changePanel(PAGEPANEL.SUBJECTS)}
                                    label={getString('pages_userPanel_subjects')}
                                />
                                {/* <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.DIPLOMAS}
                                    onClick={() => changePanel(PAGEPANEL.DIPLOMAS)}
                                    label="DYPLOMY" // todo tłumaczenie
                                /> */}
                            </>
                        ) : null}
                        {['admin', 'accounting'].some((role) => user.roles.includes(role)) ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.EMPLOYEES}
                                onClick={() => changePanel(PAGEPANEL.EMPLOYEES)}
                                label={getString('User_panel_employess_pracownicy')}
                            />
                        ) : null}
                        {user && user.roles.includes('admin') ? (
                            <>
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.MANAGE_CONTRACTS}
                                    onClick={() => changePanel(PAGEPANEL.MANAGE_CONTRACTS)}
                                    label={getString('user_panel_manage_contracts')}
                                />
                                <UserPanelButton
                                    isActive={pagePanel === PAGEPANEL.EMPLOYE_CONFIGURATION}
                                    onClick={() => changePanel(PAGEPANEL.EMPLOYE_CONFIGURATION)}
                                    label={getString('pages_userPanel_employee_configuration')}
                                />
                            </>
                        ) : null}
                        {user &&
                        ['trainer_new', 'manager', 'admin'].some((role) =>
                            user.roles.includes(role)
                        ) ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.ADD_ROLE}
                                onClick={() => changePanel(PAGEPANEL.ADD_ROLE)}
                                label={getString('pages_userPanel_zarzadzajRolami')}
                            />
                        ) : undefined}
                        {['manager', 'admin'].some((role) => user.roles.includes(role)) ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.SUMMARY}
                                onClick={() => changePanel(PAGEPANEL.SUMMARY)}
                                label={getString('User_panel_summary_summary')}
                            />
                        ) : undefined}
                        {user && user.roles.length > 0 ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.ANNOUNCEMENTS}
                                onClick={() => changePanel(PAGEPANEL.ANNOUNCEMENTS)}
                                label={getString('pages_userPanel_ogloszenia')}
                            />
                        ) : null}
                        {user && user.roles.includes('student') ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.LESSONS}
                                onClick={() => changePanel(PAGEPANEL.LESSONS)}
                                label={getString('pages_userPanel_lekcje')}
                            />
                        ) : undefined}
                        {user &&
                        ((['student', 'former_student'].some((role) => user.roles.includes(role)) &&
                            !user.parent) ||
                            user.children?.length) ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.PAYMENTS}
                                onClick={() => changePanel(PAGEPANEL.PAYMENTS)}
                                label={getString('pages_userPanel_rozliczenia')}
                            />
                        ) : undefined}
                        {user && user.roles.includes('trainer') ? <></> : null}
                        {['student', 'trainer'].some((role) => user.roles.includes(role)) ||
                        user.hasChildrenWithActiveStudentRole ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.TRAINERS}
                                onClick={() => changePanel(PAGEPANEL.TRAINERS)}
                                label={getString('User_panel_trainers_menu')}
                            />
                        ) : null}
                        {user.roles.includes('trainer') ? (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.REQ_TRAINER}
                                onClick={() => changePanel(PAGEPANEL.REQ_TRAINER)}
                                label={getString('pages_userPanel_zamowienia')}
                            />
                        ) : (
                            <UserPanelButton
                                isActive={pagePanel === PAGEPANEL.REQ}
                                onClick={() => changePanel(PAGEPANEL.REQ)}
                                label={getString('pages_userPanel_rekonstrukcje')}
                            />
                        )}
                        <UserPanelButton
                            isActive={pagePanel === PAGEPANEL.USERDATA}
                            onClick={() => changePanel(PAGEPANEL.USERDATA)}
                            label={getString('pages_userPanel_edytujDane')}
                        />
                    </StyledUserMenu>
                    <StyledButton
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="20px"
                        hasdeclaredtextalign="center"
                        hasdeclaredbgcolor={isLoggout ? '#592E28' : '#ee745c'}
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredmargin="32px 0 0 0"
                        hasdeclaredwidth="240px"
                        hasdeclaredheight="40px"
                        onClick={handleOpenLogout}
                    >
                        {getString('pages_userPanel_wylogujSie')}
                    </StyledButton>
                    <AnimatePresence>
                        {isLoggout && (
                            <StyledLoggoutWrapper
                                key={isLoggout}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.2 }}
                                ref={loggoutWrapper}
                            >
                                <StyledText
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredpadding="32px 20px 0 20px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                >
                                    {getString('pages_userPanel_czyNaPewnoChceszSie')}
                                </StyledText>
                                <div>
                                    <StyledButton
                                        hasdeclaredfontweight="600"
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredbgcolor="#ee745c"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredmargin="20px 0 0 0"
                                        hasdeclaredwidth="80px"
                                        hasdeclaredheight="40px"
                                        onClick={() => setIsLoggout(!isLoggout)}
                                        className="hover-red-effect"
                                    >
                                        {getString('pages_userPanel_nie')}
                                    </StyledButton>
                                    <StyledButton
                                        hasdeclaredfontweight="600"
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredbgcolor="#ee745c"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredcursor="pointer"
                                        hasdeclaredmargin="20px 0 0 0"
                                        hasdeclaredwidth="80px"
                                        hasdeclaredheight="40px"
                                        onClick={() => navigate('/logout', { replace: false })}
                                        className="hover-red-effect"
                                    >
                                        {getString('pages_userPanel_tak')}
                                    </StyledButton>
                                </div>
                            </StyledLoggoutWrapper>
                        )}
                    </AnimatePresence>
                </StyledRightPanel>
            </StyledUserPanel>
        </>
    );
};

export default UserPanel;
