import styled from 'styled-components';

export const StyledInputsDivWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: ${({ $hasDeclaredPadding }) => ($hasDeclaredPadding ? '5px' : '0')};
    text-align: center;
    gap: 10px;
    margin-bottom: ${({ hasmarginbottom }) => (hasmarginbottom ? '10px' : '0')};
    border: ${({ hasborder }) => (hasborder ? '4px solid #6786be' : 'none')};
    border-radius: 25px;

    @media only screen and (max-width: 600px) {
        flex-direction: ${({ $switchLang }) => ($switchLang ? 'row' : 'column')};
    }
`;
