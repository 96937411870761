import React, { useState, useMemo } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import SummaryEditElement from './SummaryEditElement';

import { StyledSummaryElement } from '../../atoms/SummaryElement/StyledSummaryElement';
import { StyledContentElement } from '../../atoms/SettlementsDoneElement/StyledContentElement';
import { StyledHeading } from '../../atoms/SettlementsDoneElement/StyledHeading';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledArrow,
    StyledHeader,
    StyledHeaderElement,
    StyledDataWrapper,
} from '../../../styles/sharedStyles';
import { StyledDataWrapperContent } from '../../atoms/SummaryElement/StyledDataWrapperContent';

import { getLang, getLocale, getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const SummaryElement = ({
    id,
    month,
    setMonth,
    students,
    trainers,
    subjects,
    trigger,
    user,
    firstElement,
    onDelete,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const isAdmin = useMemo(() => user.roles.includes('admin'), [user]);

    const handleTrigger = () => {
        trigger?.();
        setIsOpen((val) => !val);
    };

    const fixedDate = useMemo(() => {
        const stuff = id.split('-');
        stuff[1] = String(parseInt(stuff[1]) + 1);
        return stuff.join('-');
    }, [id]);

    return (
        <StyledSummaryElement>
            <StyledContentElement isfirstchild={firstElement} isopen={isOpen}>
                <StyledHeading onClick={handleTrigger}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786be"
                    >
                        {new Date(fixedDate).toLocaleDateString(getLocale(getLang()), {
                            month: 'long',
                            year: 'numeric',
                        })}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen && (
                    <StyledDataWrapper summarydata>
                        <ScrollSync>
                            <>
                                <ScrollSyncPane>
                                    <StyledHeader
                                        hasalignself="flex-start"
                                        isscrollbar={isScrollBar}
                                        ref={scrollElement}
                                        $isCheckbox={true}
                                        $secondDivMinWidth={900}
                                        $isCheckboxMinusWidth={104}
                                    >
                                        <div>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_uczen')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_data')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_godzin')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_przedmiot')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_trener')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement
                                                $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                            >
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('summary_element_cena')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            {isAdmin ? (
                                                <StyledHeaderElement
                                                    $hasWidth={isAdmin ? '14.2%' : '16.6%'}
                                                >
                                                    <StyledText
                                                        hasdeclaredfontsize="16px"
                                                        hasdeclaredfontweight="700"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredlineheight="1.4em"
                                                    >
                                                        {getString('summary_element_cena_tr')}
                                                    </StyledText>
                                                </StyledHeaderElement>
                                            ) : null}
                                        </div>
                                    </StyledHeader>
                                </ScrollSyncPane>
                                <StyledDataWrapperContent>
                                    {month.map((hour, idx) => (
                                        <SummaryEditElement
                                            hour={hour}
                                            key={idx + JSON.stringify(hour)}
                                            setHour={(val) =>
                                                setMonth((cur) =>
                                                    cur.map((v, i) =>
                                                        i === idx
                                                            ? typeof val === 'function'
                                                                ? val(v)
                                                                : val
                                                            : v
                                                    )
                                                )
                                            }
                                            deleteHour={() => {
                                                setMonth((cur) => cur.filter((_v, i) => i !== idx));
                                                onDelete();
                                            }}
                                            students={students}
                                            trainers={trainers}
                                            subjects={subjects}
                                            isAdmin={isAdmin}
                                        />
                                    ))}
                                </StyledDataWrapperContent>
                            </>
                        </ScrollSync>
                    </StyledDataWrapper>
                )}
            </StyledContentElement>
        </StyledSummaryElement>
    );
};

export default SummaryElement;
