import styled, { css } from 'styled-components';

const diff1 = css`
    > div {
        > div {
            width: 100%;
            min-width: 700px;
            display: flex;
            justify-content: space-between;
        }
    }
`;

const diff2 = css`
    > div {
        display: flex;
        width: calc(${({ calcwidth }) => (calcwidth ? '100% - 54px' : '100%')});
        gap: 5px;

        > div {
            > div {
                width: 100%;
                min-width: 700px;
                display: flex;
                justify-content: space-between;
            }
        }
    }
`;

export const StyledFlexBoxWrapper = styled.div`
    width: ${({ calcwidth }) => (calcwidth ? 'calc(100% - 50px)' : '100%')};
    display: flex;
    align-self: flex-end;
    justify-content: ${({ calcwidth }) => (calcwidth ? 'flex-start' : 'flex-end')};
    flex-direction: ${({ diff }) => (diff ? 'column' : 'row')};
    align-items: center;
    gap: 8px;

    ${({ diff }) => (diff ? diff2 : diff1)};
`;
