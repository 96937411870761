import { registerUpgrade } from '../../../logic/requests/events';
import { setupEventPayment } from '../../../logic/requests/payments.js';
import downloadFile from '../../../utils/downloadFile.js';

import REGISTER_STATUS from '../../constants/register_status.json';

import { getString } from '../../../strings';
import { getDateTimeString } from '../../../logic/eventtime.js';

export const handleRegisterUpgrade = (event, setEvent) => {
    registerUpgrade(event.id)
        .then((res) => {
            setEvent({
                ...event,
                user_register_status: 1,
            });
        })
        .catch((err) => {
            console.error('registerUpgrade', err);
        });
};

export const handlePay = (event, setPaymentDeadlinePopup, setRegistrationClosedPopup, setEvent) => {
    // TODO: Pokazać tu loader?
    setupEventPayment(event.id)
        .then((res) => {
            if (!res.token) {
                // TODO: pokazać błąd? to nie powinno się zdażyć
                return;
            }
            // TODO: Pokazać coś chwilę przed przekierowaniem?
            const service = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === "uat" ? 'secure' : 'sandbox';
            window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
        })
        .catch((err) => {
            if (err.data.code) {
                switch (err.data.code) {
                    case 'deadline':
                        setEvent({
                            ...event,
                            pending_deadline_expired: 1,
                        });
                        setPaymentDeadlinePopup?.(
                            getDateTimeString(new Date(event.pending_deadline))
                        );
                        break;
                    case "closed_registration":
                        setEvent({
                            ...event,
                            register_status: "closed",
                        });
                        setRegistrationClosedPopup?.(
                            getDateTimeString(new Date(event.register_time_end))
                        );
                        break;
                    default:
                        break;
                }
            }
        });
};

export const REGISTER_STATUS_TEST = {
    [REGISTER_STATUS['UNCONFIRMED']]: getString('userPanel_userPanelTournament_oczekujacy'),
    [REGISTER_STATUS['CONFIRMED']]: getString('userPanel_userPanelTournament_zatwierdzony'),
    [REGISTER_STATUS['REJECTED']]: getString('userPanel_userPanelTournament_odrzucony'),
    [REGISTER_STATUS['TRANSACTION_PENDING']]: getString(
        'userPanel_userPanelTournament_platnosc_w_toku'
    ),
};

export const downloadCubeComps = (event) =>
    downloadFile(`/api/admin/cubecomps/${event.id}`, `cubecomps_${event.code}.csv`);

export const downloadContactList = (event) =>
    downloadFile(`/api/admin/contact-list/${event.id}`, `lista_kontaktowa_${event.code}.csv`);

export const downloadABCD = (event) =>
    downloadFile(`/api/admin/abcd/${event.id}`, `abcd_${event.code}.csv`);

export const downloadIDCards = (event) =>
    downloadFile(`/api/events/get-ids/${event.code}`, `id-cards_${event.code}.pdf`);

export const downloadThanksCards = (event) =>
    downloadFile(`/api/events/admin/get-thanks/${event.id}`, `thanks_${event.code}.pdf`);

export const downloadDiplomaCards = (event) =>
    downloadFile(`/api/events/admin/get-diploma/${event.id}`, `diplomas_${event.code}.pdf`);

export const downloadMedalsCards = (event) =>
    downloadFile(`/api/events/admin/get-medals/${event.id}`, `medals_${event.code}.pdf`);

export const downloadCupsCards = (event) =>
    downloadFile(`/api/events/admin/get-cups/${event.id}`, `cups_${event.code}.pdf`);
