import React, { useState, useMemo, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledSummaryEditElement } from '../../atoms/SummaryEditElement/StyledSummaryEditElement';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledClosePayElement, StyledHeaderElement } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSummaryElementData } from '../../atoms/SummaryEditElement/StyledSummaryElementData';
import { StyledEditInputElement } from '../../../styles/sharedStyles';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledEditWrapper } from '../../atoms/SummaryElement/StyledEditWrapper';
import { StyledButtonsWrapper } from '../../atoms/Payments/StyledButtonsWrapper';
import { StyledUPopupCloseButton } from '../../atoms/Payments/StyledUPopupCloseButton';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import { getDateString } from '../../../logic/eventtime';
import { getLang, getString } from '../../../strings';
import { deleteHours, editSummary } from '../../../logic/requests/students';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';
import { StyledInputEditWrapper } from '../../atoms/SummaryElement/StyledInputEditWrapper';

const handleSubmit = (id, data, defaultValues, onSubmit) => {
    const toSend = Object.entries(data).reduce((out, [key, val]) => {
        if (defaultValues[key] !== val) out[key] = val;
        return out;
    }, {});

    if (toSend.hours) {
        toSend.hours = toSend.hours.replace(',', '.');
        toSend.half = toSend.hours === '0.5' ? 1 : 0;
        const int = parseInt(toSend.hours);
        if (isNaN(toSend.hours)) {
            console.warn('handleSubmit', 'invalidValue', 'toSend.hours', toSend.hours, int);
            return;
        }
        toSend.hours = int;
    }
    toSend.price = toSend.price?.replace(',', '.');
    toSend.price_trainer = toSend.price_trainer?.replace(',', '.');

    if (!Object.keys(toSend).length) return onSubmit?.();

    editSummary(id, toSend)
        .then((res) => {
            onSubmit?.(res);
        })
        .catch((err) => {
            console.error('editSummary', err);
        });
};

const handleDelete = (
    id,
    isAdmin,
    onSuccess,
    confirmPopup,
    setConfirmPopup,
    reason,
    setTextareaError
) => {
    if (!confirmPopup) setConfirmPopup(true);

    if (confirmPopup) {
        if (!isAdmin && !reason) {
            setTextareaError('Podaj powód');

            return;
        }

        deleteHours(id, reason)
            .then(() => {
                onSuccess?.();
            })
            .catch((err) => {
                console.error('handleDelete', err);
            });
    }
};

const SummaryEditElement = ({
    hour,
    setHour,
    deleteHour,
    students,
    trainers,
    subjects,
    isAdmin,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [reason, setReason] = useState(false);
    const [textareaError, setTextareaError] = useState(null);

    const student = useMemo(() => `${hour.student.firstname} ${hour.student.lastname}`, [hour]);
    const trainer = useMemo(() => `${hour.trainer.firstname} ${hour.trainer.lastname}`, [hour]);

    const defaultValues = useMemo(
        () => ({
            student: hour.student.id,
            date: new Date(hour.date).toISOString().split('T')[0],
            hours: hour.half ? '0.5' : hour.hours,
            subject: hour.subject,
            trainer: hour.trainer.id,
            price: hour.price,
            price_trainer: hour.price_trainer,
        }),
        [hour]
    );

    const price = useMemo(() => new Decimal(hour.price), [hour]);
    const price_trainer = useMemo(() => new Decimal(hour.price_trainer), [hour]);

    const values = {
        student: useRef(),
        date: useRef(),
        hours: useRef(),
        subject: useRef(),
        trainer: useRef(),
        price: useRef(),
        price_trainer: useRef(),
    };

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup>
                    <div
                        style={{
                            widht: '100%',
                            height: '100%',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredpadding="50px 20px 50px 20px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtextalign="center"
                        >
                            {getString('summary_edit_element_czy_na_pewno')}
                        </StyledText>
                        {!isAdmin ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    paddingBottom: '30px',
                                }}
                            >
                                <StyledText
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredtextalign="center"
                                >
                                    {getString('summary_edit_element_podaj_powod')}
                                </StyledText>
                                <StyledInputEditWrapper>
                                    <textarea
                                        style={{
                                            width: '300px',
                                            height: '100px',
                                            resize: 'none',
                                            border: `2px solid ${
                                                textareaError ? 'red' : '#6786BE'
                                            }`,
                                            borderRadius: '12px',
                                            padding: '12px',
                                            fontSize: '16px',
                                            fontFamily: 'Poppins',
                                        }}
                                        type="text"
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                </StyledInputEditWrapper>
                            </div>
                        ) : null}
                        <StyledButtonsWrapper>
                            <StyledButton
                                onClick={() =>
                                    handleDelete(
                                        hour.id,
                                        isAdmin,
                                        deleteHour,
                                        confirmPopup,
                                        setConfirmPopup,
                                        reason,
                                        setTextareaError
                                    )
                                }
                            >
                                USUŃ
                            </StyledButton>
                            <StyledUPopupCloseButton onClick={() => setConfirmPopup(false)}>
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredfontcolor="#fff"
                                    hascursor="pointer"
                                >
                                    {getString('payments_paymentsPopup_zamknij')}
                                </StyledText>
                            </StyledUPopupCloseButton>
                        </StyledButtonsWrapper>
                    </div>
                </PaymentsPopup>
            ) : null}
            <StyledSummaryEditElement>
                <ScrollSyncPane>
                    <div>
                        {!isEdit ? (
                            <StyledSummaryElementData>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {student}
                                    </StyledText>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {getDateString(new Date(hour.date))}
                                    </StyledText>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {hour.half ? '0.5' : hour.hours}
                                    </StyledText>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {
                                            subjects?.find(
                                                (el) => el.description && el.id === hour.subject
                                            )?.description[getLang()]
                                        }
                                    </StyledText>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {trainer}
                                    </StyledText>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                    </StyledText>
                                </StyledHeaderElement>
                                {isAdmin ? (
                                    <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                        <StyledText
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredlineheight="1.4em"
                                        >
                                            {price_trainer.dp() > 0
                                                ? price_trainer.toFixed(2)
                                                : price_trainer.toString()}{' '}
                                            PLN
                                        </StyledText>
                                    </StyledHeaderElement>
                                ) : null}
                            </StyledSummaryElementData>
                        ) : (
                            <StyledEditWrapper>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <select
                                            defaultValue={defaultValues.student}
                                            ref={values.student}
                                        >
                                            {students.map((student) => (
                                                <option key={student.id} value={student.id}>
                                                    {student.lastname} {student.firstname}
                                                </option>
                                            ))}
                                        </select>
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <input
                                            type="date"
                                            defaultValue={defaultValues.date}
                                            ref={values.date}
                                        />
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <input
                                            type="text"
                                            defaultValue={defaultValues.hours}
                                            ref={values.hours}
                                        />
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <select
                                            defaultValue={defaultValues.subject}
                                            ref={values.subject}
                                        >
                                            {subjects
                                                .filter((el) => el.description)
                                                .map((subject) => (
                                                    <option key={subject.id} value={subject.id}>
                                                        {subject.description[getLang()]}
                                                    </option>
                                                ))}
                                        </select>
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <select
                                            defaultValue={defaultValues.trainer}
                                            ref={values.trainer}
                                        >
                                            {trainers.map((trainer) => (
                                                <option key={trainer.id} value={trainer.id}>
                                                    {trainer.lastname} {trainer.firstname}
                                                </option>
                                            ))}
                                        </select>
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                    <StyledEditInputElement $hasDeclaredWidth="100%">
                                        <input
                                            type="text"
                                            defaultValue={defaultValues.price}
                                            ref={values.price}
                                        />
                                    </StyledEditInputElement>
                                </StyledHeaderElement>
                                {isAdmin ? (
                                    <StyledHeaderElement $hasWidth={isAdmin ? '14.2%' : '16.6%'}>
                                        <StyledEditInputElement $hasDeclaredWidth="100%">
                                            <input
                                                type="text"
                                                defaultValue={defaultValues.price_trainer}
                                                ref={values.price_trainer}
                                            />
                                        </StyledEditInputElement>
                                    </StyledHeaderElement>
                                ) : null}
                            </StyledEditWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
                <StyledSavePayElement
                    isedit={isEdit}
                    onClick={
                        isEdit
                            ? () =>
                                  handleSubmit(
                                      hour.id,
                                      Object.fromEntries(
                                          Object.entries(values).map(([key, val]) => [
                                              key,
                                              ['student', 'trainer'].includes(key)
                                                  ? parseInt(val?.current?.value)
                                                  : val?.current?.value,
                                          ])
                                      ),
                                      defaultValues,
                                      (newHour) => {
                                          setIsEdit(false);
                                          setHour((hour) => ({
                                              ...hour,
                                              ...newHour,
                                              student: newHour?.student
                                                  ? students.find((s) => s.id === newHour.student)
                                                  : hour.student,
                                              trainer: newHour?.trainer
                                                  ? trainers.find((t) => t.id === newHour.trainer)
                                                  : hour.trainer,
                                          }));
                                      }
                                  )
                            : () => setIsEdit(true)
                    }
                >
                    {!isEdit ? <PencilIcon /> : <AcceptIcon />}
                </StyledSavePayElement>
                <StyledClosePayElement
                    disabled={hour.paid === 1 && hour.free !== 1}
                    onClick={() =>
                        handleDelete(
                            hour.id,
                            isAdmin,
                            deleteHour,
                            confirmPopup,
                            setConfirmPopup,
                            reason,
                            setTextareaError
                        )
                    }
                >
                    <CloseIcon />
                </StyledClosePayElement>
            </StyledSummaryEditElement>
        </>
    );
};

export default SummaryEditElement;
