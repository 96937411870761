import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Flag from 'react-world-flags';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelSubjectElement } from '../../atoms/UserPanelSubjects/StyledUserPanelSubjectElement';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';

import { getString, getLang } from '../../../strings';
import { addSubject } from '../../../logic/requests/students';
import { formSchema } from './UserPanelSubjectElement.data';
import { StyledFlag } from '../../../styles/sharedStyles';
import { useQueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';

const UserPanelSubjectElement = ({ onSuccessfulAdd }) => {
    const queryClient = useQueryClient();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            namePL: '',
            nameGB: '',
            nameUA: '',
            nameCZ: '',
            studentPrice: '',
            trainerPrice: '',
        },
    });

    const onSubmit = useCallback(
        (data) => {
            addSubject(data).then(() => {
                onSuccessfulAdd([
                    {
                        title:
                            getString('UserPanelSubjects__success__infos__title__subject') +
                            ' PL: ',
                        value: `${data.namePL}`,
                    },
                    {
                        title:
                            getString('UserPanelSubjects__success__infos__title__subject') +
                            ' GB: ',
                        value: data.nameGB || '-',
                    },
                    {
                        title:
                            getString('UserPanelSubjects__success__infos__title__subject') +
                            ' UA: ',
                        value: data.nameUA || '-',
                    },
                    {
                        title:
                            getString('UserPanelSubjects__success__infos__title__subject') +
                            ' CZ: ',
                        value: data.nameCZ || '-',
                    },
                    {
                        title: getString('UserPanelSubjects__success__infos__price_student') + ':',
                        value: new Decimal(data.studentPrice).toFixed(2),
                    },
                    {
                        title: getString('UserPanelSubjects__success__infos__price_trainer') + ':',
                        value: new Decimal(data.trainerPrice).toFixed(2),
                    },
                ]);
                queryClient.invalidateQueries({ queryKey: ['subjects', 'all'] });
                reset();
            });
        },
        [queryClient, reset]
    );

    return (
        <>
            <StyledUserPanelSubjectElement onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="nazwaPrzedmiotu">
                    <StyledText
                        hasdeclaredfontsize="20px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="8px 0 8px 0"
                        hasdeclaredfontweight="600"
                    >
                        {getString('User_panel_subject_element_nazwa_przedmiotu')}
                    </StyledText>
                </label>
                <StyledInputsDivWrapper $switchLang>
                    <StyledInputWrapper $switchLang $isCenter hasdeclaredwidth="70px">
                        <StyledFlag>
                            <Flag code="PL" alt={getLang().toUpperCase()} />
                        </StyledFlag>
                    </StyledInputWrapper>
                    <StyledInputWrapper
                        error={errors?.['namePL']}
                        $switchLang
                        hasdeclaredwidth="calc(100% - 80px)"
                        $withoutInputMarginBottom
                    >
                        <input id="nazwaPrzedmiotu" type="text" {...register('namePL')} />
                        {errors?.['namePL'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_nazwa_przedmiotu_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                </StyledInputsDivWrapper>
                <StyledInputsDivWrapper $switchLang>
                    <StyledInputWrapper $switchLang $isCenter hasdeclaredwidth="70px">
                        <StyledFlag>
                            <Flag code="GB" alt={getLang().toUpperCase()} />
                        </StyledFlag>
                    </StyledInputWrapper>
                    <StyledInputWrapper
                        error={errors?.['nameGB']}
                        $switchLang
                        hasdeclaredwidth="calc(100% - 80px)"
                        $withoutInputMarginBottom
                    >
                        <input id="nazwaPrzedmiotu" type="text" {...register('nameGB')} />
                        {errors?.['nameGB'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_nazwa_przedmiotu_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                </StyledInputsDivWrapper>
                <StyledInputsDivWrapper $switchLang>
                    <StyledInputWrapper $switchLang $isCenter hasdeclaredwidth="70px">
                        <StyledFlag>
                            <Flag code="UA" alt={getLang().toUpperCase()} />
                        </StyledFlag>
                    </StyledInputWrapper>
                    <StyledInputWrapper
                        error={errors?.['nameUA']}
                        $switchLang
                        hasdeclaredwidth="calc(100% - 80px)"
                        $withoutInputMarginBottom
                    >
                        <input id="nazwaPrzedmiotu" type="text" {...register('nameUA')} />
                        {errors?.['nameUA'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_nazwa_przedmiotu_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                </StyledInputsDivWrapper>
                <StyledInputsDivWrapper $switchLang>
                    <StyledInputWrapper $switchLang $isCenter hasdeclaredwidth="70px">
                        <StyledFlag>
                            <Flag code="CZ" alt={getLang().toUpperCase()} />
                        </StyledFlag>
                    </StyledInputWrapper>
                    <StyledInputWrapper
                        error={errors?.['name']}
                        $switchLang
                        hasdeclaredwidth="calc(100% - 80px)"
                        $withoutInputMarginBottom
                    >
                        <input id="nazwaPrzedmiotu" type="text" {...register('nameCZ')} />
                        {errors?.['nameCZ'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_nazwa_przedmiotu_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                </StyledInputsDivWrapper>
                <StyledInputsDivWrapper hasmarginbottom>
                    <StyledInputWrapper error={errors?.['studentPrice']}>
                        <label htmlFor="cenaZaGodzine">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_cena_ucznia')}
                            </StyledText>
                        </label>
                        <input
                            id="cenaZaGodzine"
                            step="0.01"
                            type="number"
                            {...register('studentPrice')}
                        />
                        {errors?.['studentPrice'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_cena_ucznia_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                    <StyledInputWrapper error={errors?.['trainerPrice']}>
                        <label htmlFor="kwotaDodatkowa">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_cena_trenera')}
                            </StyledText>
                        </label>
                        <input
                            id="kwotaDodatkowa"
                            step="0.01"
                            type="number"
                            {...register('trainerPrice')}
                        />
                        {errors?.['trainerPrice'] ? (
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {getString('User_panel_subject_element_cena_trenera_error')}
                            </StyledText>
                        ) : null}
                    </StyledInputWrapper>
                </StyledInputsDivWrapper>
                <StyledInputsButtonsWrapper>
                    <input
                        type="submit"
                        value={getString('User_panel_subject_element_cena_trenera_zapisz')}
                    />
                    <button type="button" className="reset" onClick={() => reset()}>
                        {getString('User_panel_subject_element_cena_trenera_anuluj')}
                    </button>
                </StyledInputsButtonsWrapper>
            </StyledUserPanelSubjectElement>
        </>
    );
};

export default UserPanelSubjectElement;
