import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { StyledFlag, StyledEditInputElement } from '../../../styles/sharedStyles';
import {
    StyledSubjectDataElement,
    StyledSubjectDataTextElement,
} from './SubjectDataElement.styles';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';

import Flag from 'react-world-flags';
import { getLang, getString } from '../../../strings';

const SubjectDataElement = ({ lang, isEdit, errors, register, name, value }) => {
    return (
        <>
            <StyledSubjectDataElement>
                <ScrollSyncPane>
                    <div>
                        {isEdit ? (
                            <StyledEditWrapper>
                                <StyledEditInputElement $hasDeclaredWidth="10%">
                                    <StyledFlag $hasDeclaredMarginLeft="20px">
                                        <Flag code={lang} alt={getLang().toUpperCase()} />
                                    </StyledFlag>
                                </StyledEditInputElement>
                                <StyledEditInputElement
                                    error={errors?.[name]}
                                    $isFlex
                                    $flexDirection="column"
                                    $hasDeclaredWidth="90%"
                                >
                                    <input type="text" {...register(name)} />
                                    {errors?.[name] ? (
                                        <StyledText
                                            as="h4"
                                            hasdeclaredfontsize="16px"
                                            hasdeclaredfontcolor="#F74017"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredlineheight="1.4em"
                                        >
                                            {getString(
                                                'User_panel_subject_element_nazwa_przedmiotu_error'
                                            )}
                                        </StyledText>
                                    ) : null}
                                </StyledEditInputElement>
                            </StyledEditWrapper>
                        ) : (
                            <StyledGlobalUserPanelWrapper>
                                <StyledSubjectDataTextElement $hasDeclaredWidth="10%">
                                    <StyledFlag>
                                        <Flag code={lang} />
                                    </StyledFlag>
                                </StyledSubjectDataTextElement>
                                <StyledSubjectDataTextElement $isFlex $hasDeclaredWidth="90%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredpadding="0 0 0 15px"
                                    >
                                        {value(name)}
                                    </StyledText>
                                </StyledSubjectDataTextElement>
                            </StyledGlobalUserPanelWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
            </StyledSubjectDataElement>
        </>
    );
};

export default SubjectDataElement;
