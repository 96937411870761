import styled from 'styled-components';

export const StyledAnnouncementFormRoles = styled.div`
    display: grid;
    background-color: white;
    border: 4px solid #6786be;
    border-radius: 25px;
    gap: 8px;
    padding: 16px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledCheckboxWrapperForRoles = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;

export const StyledPlatformsWrapper = styled.div`
    background-color: white;
    border: 4px solid #6786be;
    border-radius: 25px;
    gap: 15px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
