import React, { useState, useEffect, useCallback } from 'react';

import Loader from '../Loader/Loader';
import SummaryElement from './SummaryElement';

import { StyledUserPanelSummary } from '../../atoms/UserPanelSummary/StyledUserPanelSummary';

import {
    listHoursSummary,
    listStudents,
    listSubjects,
    listTrainers,
} from '../../../logic/requests/students';
import {
    StyledLoaderWrapper,
    StyledGlobalScrollWrapper,
    StyledGlobalHeadingWrapper,
} from '../../../styles/sharedStyles';

import { getLang, getString } from '../../../strings';
import { cebulaCompare } from '../../../logic/arrays';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

const UserPanelSummary = ({ user }) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar();
    const [summary, setSummary] = useState();
    const [students, setStudents] = useState();
    const [trainers, setTrainers] = useState();
    const [subjects, setSubjects] = useState();

    const fetchSummary = useCallback(() => {
        listHoursSummary()
            .then((res) => {
                setSummary(res);
            })
            .catch((err) => {
                console.error('listHoursSummary', err);
            });
    }, [setSummary]);

    useEffect(() => {
        fetchSummary();
        listStudents()
            .then((res) => {
                setStudents(
                    res.sort((a, b) =>
                        cebulaCompare(
                            `${a.lastname} ${a.firstname}`,
                            `${b.lastname} ${b.firstname}`
                        )
                    )
                );
            })
            .catch((err) => {
                console.error('listStudents', err);
            });

        listTrainers()
            .then((res) => {
                setTrainers(
                    res.sort((a, b) =>
                        cebulaCompare(
                            `${a.lastname} ${a.firstname}`,
                            `${b.lastname} ${b.firstname}`
                        )
                    )
                );
            })
            .catch((err) => {
                console.error('listTrainers', err);
            });

        listSubjects()
            .then((res) => {
                setSubjects(
                    res
                        .filter((el) => el.description)
                        .sort((a, b) =>
                            cebulaCompare(a.description[getLang()], b.description[getLang()])
                        )
                );
            })
            .catch((err) => {
                console.error('listSubjects', err);
            });
    }, [fetchSummary, setSummary]);

    return (
        <StyledUserPanelSummary>
            {!(summary && students && trainers && subjects) ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledGlobalHeadingWrapper>
                        <UserPanelHeadingText
                            text={`${getString('User_panel_summary_summary')}:`}
                        />
                    </StyledGlobalHeadingWrapper>
                    <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        <div>
                            {Object.entries(summary).map(([id, month], index) => (
                                <SummaryElement
                                    id={id}
                                    key={id}
                                    month={month}
                                    setMonth={(val) =>
                                        setSummary((cur) => {
                                            cur[id] =
                                                typeof val === 'function' ? val(cur[id]) : val;
                                            return { ...cur };
                                        })
                                    }
                                    onDelete={fetchSummary}
                                    students={students}
                                    trainers={trainers}
                                    subjects={subjects}
                                    trigger={trigger}
                                    user={user}
                                    firstElement={index === 0}
                                />
                            ))}
                        </div>
                    </StyledGlobalScrollWrapper>
                </>
            )}
        </StyledUserPanelSummary>
    );
};

export default UserPanelSummary;
